import { nfa } from '@netflix/nfa-logger/lib/helper';
import { Key } from 'react';
import { FullStory } from '@fullstory/browser';

export type SerializedAssetId = string;

export const thumbnailUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/thumbnail${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const proxyUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/proxy${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const previewUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/preview${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const formattedAppName = (app: string): string => {
  switch (app) {
    case `VANDAM`:
      return `VanDAM`;
    case `PAL`:
      return `PAL`;
    default:
      return app;
  }
};

type Attribute = string | boolean | number | null | undefined | Key | Attribute[];
type Attributes = Record<string, Attribute | Attribute[]>;

export const logEvent = (name: string, category: string, attributes?: Attributes): void => {
  nfa.logFeatureEngaged(name, category, attributes);
  FullStory('trackEvent', { name: name, properties: { category: category, ...attributes } });
};

export const logDuration = (name: string, duration: number) => {
  nfa.reportDuration(name, duration);
  FullStory('log', {
    level: 'log',
    msg: `Duration: ${name} - ${duration}ms`,
  });
};

export const logError = (e: Error): void => {
  nfa.logExceptionOccurred(e);
  FullStory('log', {
    level: 'error',
    msg: e.message || e.name,
  });
};
