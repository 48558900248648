import { Button, Text, useCanImageLoad } from '@hawkins/components';
import React, { ReactElement, SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@hawkins/styles';
import { Controller, MediaPlayer, MediaPlayerVariant } from '@hawkins-community/media-player';
import { ProgressBar } from '@hawkins-community/media-player/components/ProgressBar';
import { IconDownload, IconExpand } from '@hawkins/assets';
import { AssetBrowserItem, AssetBrowserItemProps } from '@hawkins-community/asset-browser/v4';
import { PlaybackController } from '@hawkins-community/media-player/components/PlaybackController';
import bytes from 'bytes';

import { useAssetDetail } from '../batch/useDetailCache';
import { logEvent, proxyUrl, thumbnailUrl } from '../../util';
import { CounterChip } from '../CounterChip';
import { PasswordContext } from '../../context';
import { ErrorChildren, ThumbnailError } from '../Thumbnail';
import { FileName } from '../FileName';
import { useCanDownload } from '../../hooks/useCanDownload';

interface ShareItemProps {
  itemId: string;
  index: number;
  total: number;
}
const useItemStyles = makeStyles(({ Colors, Dimensions, Utils }) => ({
  item: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr fit-content(100%)',
      alignItems: 'initial',
    },
  },

  actionContainer: {
    display: 'none',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      gridColumnStart: 6,
      gridColumnEnd: 7,
      gridRowStart: 2,
      gridRowEnd: 7,
      alignSelf: 'start',
      justifySelf: 'end',
      display: 'flex',
      flexDirection: 'column',
      gap: Dimensions.Space8,
      zIndex: 10,
    },
  },

  indexChip: {
    gridColumnStart: 2,
    gridColumnEnd: 5,
    gridRowStart: 2,
    gridRowEnd: 5,
    alignSelf: 'start',
    justifySelf: 'start',
  },

  button: {
    borderRadius: '50%',
    backgroundColor: Colors.BlackT40,
    width: Dimensions.RawSize32,
    height: Dimensions.RawSize32,

    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      backgroundColor: Colors.GrayT70,
    },
  },

  fileSizeContainer: {
    display: 'none',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      display: 'inline-block',
    },
  },

  fileDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: Dimensions.Space2,
    paddingLeft: Dimensions.Space8,
    gap: Dimensions.Space8,
    alignItems: 'center',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      alignItems: 'flex-start',
    },
  },

  buttonViewDetailsMobile: {
    [Utils.breakpoints.up('BreakpointSmall')]: {
      display: 'none',
    },
  },
}));
const useGridMediaPlayerStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  controls: {
    position: 'absolute',
    width: '100%',
    bottom: '25%',
    opacity: '0.5',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      opacity: 1,
    },
  },
});

const GridMediaPlayer = (props: { itemId: string }) => {
  const { itemId } = props;
  const { shareKey } = useParams();
  const [controller, setController] = useState<Controller | null>(null);
  const classes = useGridMediaPlayerStyles();
  const password = useContext(PasswordContext);
  const { data: assetDetails } = useAssetDetail(shareKey || null, itemId, password);
  // const mediaPlayerConfig = useMemo(
  //   () => ({
  //     customControls: { left: [], right: [], center: [new PlayPauseControl(1)] },
  //   }),
  //   [],
  // );
  const onClick = useCallback((evt: SyntheticEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
  }, []);
  if (assetDetails && assetDetails.isVideoPlayable && shareKey) {
    return (
      <div className={classes.container}>
        <MediaPlayer
          variant={MediaPlayerVariant.NoControls}
          onInit={setController}
          // config={mediaPlayerConfig}
          autoPlay
          viewable={{
            video: {
              url: proxyUrl(shareKey, itemId.toString(), password),
              frameRateTag: 'FPS_2397',
            },
          }}
          watermark={{
            hide: true,
          }}
        />
        {controller ? (
          <div className={classes.controls} onClick={onClick}>
            <ProgressBar controller={controller as PlaybackController} />
          </div>
        ) : null}
      </div>
    );
  }
  return null;
};

export const GridItem = (props: ShareItemProps): ReactElement | null => {
  const { itemId, index, total } = props;
  const { shareKey } = useParams();
  const password = useContext(PasswordContext);

  const [objectFit, setObjectFit] = useState<AssetBrowserItemProps['objectFit']>('cover');
  const calculateFit = useCallback<NonNullable<Required<AssetBrowserItemProps>['onLoad']>>((e) => {
    const image = e.target as HTMLImageElement;
    const objectFit = image.naturalWidth > image.naturalHeight ? 'cover' : 'contain';
    setObjectFit(objectFit);
  }, []);

  const classes = useItemStyles();
  const { data: assetDetails } = useAssetDetail(shareKey || null, itemId, password);
  const status = useCanImageLoad(thumbnailUrl(shareKey || '', itemId, password));
  const playerItem = useMemo(
    () =>
      status === 'loaded' && assetDetails?.isVideoPlayable === true ? <GridMediaPlayer itemId={itemId} /> : undefined,
    [assetDetails, itemId, status],
  );

  const canDownload = useCanDownload(itemId, password);

  const source = useMemo(() => {
    if (status === 'loading' || !shareKey) {
      return undefined;
    }

    if (status === 'error' || assetDetails?.watermarkStatus === 'IN_PROGRESS') {
      return (
        <ThumbnailError>
          <ErrorChildren assetDetails={assetDetails} />
        </ThumbnailError>
      );
    }

    return thumbnailUrl(shareKey, itemId, password);
  }, [assetDetails, itemId, password, shareKey, status]);

  if (!itemId || !shareKey) {
    return null;
  }

  return (
    <div className={classes.item}>
      <AssetBrowserItem
        itemKey={itemId}
        objectFit={status === 'loaded' ? objectFit : 'contain'}
        // hoverSrc={playerItem}
        highlightSrc={playerItem}
        src={source}
        alt={assetDetails?.name || ''}
        onLoad={calculateFit}
      >
        <div
          className={classes.actionContainer}
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
          }}
        >
          <Button
            className={classes.button}
            variant='icon'
            compact
            disabled={!canDownload}
            icon={IconDownload}
            component={Link}
            onClick={(evt) => {
              if (!canDownload) {
                return;
              }
              logEvent('downloadAsset', 'download', {
                assetIds: assetDetails?.assetId || '',
                mimeType: assetDetails?.mimeType || '',
                size: assetDetails?.sizeInBytes || 0,
                experience: 'redirect',
              });
            }}
            to={`/${shareKey}/${itemId}/download`}
          >
            {canDownload ? 'Download' : 'Downloads are disabled for this share'}
          </Button>
          <Button
            className={classes.button}
            compact
            variant='icon'
            icon={IconExpand}
            component={Link}
            to={`/${shareKey}/${itemId}`}
          >
            View Details
          </Button>
        </div>
        <CounterChip index={index} total={total} />
      </AssetBrowserItem>
      <div className={classes.fileDetailsContainer}>
        <FileName variant='label' bold>
          {assetDetails?.name || ''}
        </FileName>
        {assetDetails?.sizeInBytes ? (
          <Text bold level={0} classes={{ root: classes.fileSizeContainer }} component={'span'}>
            {bytes(assetDetails.sizeInBytes)}
          </Text>
        ) : null}
        <Button
          className={classes.buttonViewDetailsMobile}
          variant='icon'
          icon={IconExpand}
          component={Link}
          to={`/${shareKey}/${itemId}`}
        />
      </div>
    </div>
  );
};
