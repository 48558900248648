import React, { Key, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppLogo, Button, HorizontalNavigation, Modal, Text } from '@hawkins/components';
import { IconDownload, IconX } from '@hawkins/assets';
import { ABContext, AssetBrowserContext, Carousel } from '@hawkins-community/asset-browser/v4';
import { makeStyles } from '@hawkins/styles';

import { useDownloadURL, useScreenContext } from '../../hooks';
import { BatchContext, PasswordContext } from '../../context';
import { Thumbnail } from '../Thumbnail';
import { CounterChip } from '../CounterChip';
import { logDuration, logError, logEvent, thumbnailUrl } from '../../util';
import { useAssetDetail } from '../batch/useDetailCache';
import { useCanDownload } from '../../hooks/useCanDownload';
import { useOpenGraphProtocol } from '../../hooks/useOpenGraphProtocol';

import { DetailPreviewItem } from './DetailPreviewItem';

const files = new Map<string, File>();
const useStyles = makeStyles(({ Colors, Dimensions, Theme, Utils }) => ({
  modalRoot: {
    // Fix for android devices where 100vh tends to be higher than the actual screen height, this is not required for iOS devices
    // as they handle 100vh correctly, and will be applied only in mobile, for desktop will be 100vh (as initially set)
    blockSize: 'calc(100vh - calc(100vh - 100%))',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      blockSize: '100vh',
    },
  },
  modalContainer: {
    padding: 0,
  },
  controls: {
    display: 'none',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      display: 'contents',
    },
  },
  logo: {
    display: 'none',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      display: 'flex',
    },
  },

  userControls: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadButton: {
    [Utils.breakpoints.up('BreakpointSmall')]: {
      display: 'none',
    },
  },
  downloadButtonLoader: {
    // Use important to override the additional margin injected inline.
    margin: '0 !important',
  },
  horizontalNavigation: {
    backgroundColor: Colors.Black,
    gridTemplate: 'unset',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      backgroundColor: Theme.SurfaceBackgroundColor3,
      gridTemplate: `"logo tier-1-main-content" ${Dimensions.Space72}". tier-2-content" ${Dimensions.Space56} / min-content 1fr`,
    },
  },

  logoContainer: {
    gridColumn: 1,
    [Utils.breakpoints.up('BreakpointSmall')]: {
      gridColumn: 'initial',
    },
  },

  tier1MainContent: {
    gridColumn: 2,
    [Utils.breakpoints.up('BreakpointSmall')]: {
      gridColumn: 'initial',
    },
  },

  fileName: {
    width: 190,
    display: 'inline-block',
    [Utils.breakpoints.up('399px')]: {
      width: 200,
    },
    [Utils.breakpoints.up('399px')]: {
      width: 220,
    },
    [Utils.breakpoints.up('499px')]: {
      width: 320,
    },
    [Utils.breakpoints.up('599px')]: {
      width: 420,
    },
    [Utils.breakpoints.up('699px')]: {
      width: 520,
    },
    [Utils.breakpoints.up('799px')]: {
      width: 620,
    },
    [Utils.breakpoints.up('899px')]: {
      width: 720,
    },
    [Utils.breakpoints.up('BreakpointSmall')]: {
      display: 'none',
    },
  },

  scrollGallery: {
    display: 'none',
    [Utils.breakpoints.up('BreakpointSmall')]: {
      display: 'grid',
      height: 140,
      backgroundColor: Colors.WhiteT20,
      gridAutoFlow: 'column',
      overflowX: 'scroll',
    },
  },
}));

export const DetailModal = (): React.ReactElement | null => {
  const { shareKey, focusKey = null, action } = useParams();
  const navigate = useNavigate();
  const { isHeightSmall, isWidthSmall } = useScreenContext();
  const ribbonRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const password = useContext(PasswordContext);
  const batch = useContext(BatchContext);
  const adjustRibbonVisible = useCallback((focussedItemId: Key) => {
    setTimeout(() => {
      if (ribbonRef.current && focussedItemId) {
        const matched = ribbonRef.current.querySelector(`[data-itemid='${focussedItemId}']`);
        if (matched) {
          matched.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
      }
    }, 100);
  }, []);
  const assetIds = useMemo(() => {
    logEvent('previewPageLoad', 'preview', {
      shareId: shareKey,
      assetIds: batch?.assetIds.map((asset) => asset.id),
      focusItem: focusKey,
    });
    return (batch?.assetIds || []).map((item) => item.id);
  }, [batch, focusKey, shareKey]);
  const gotoShare = useCallback(() => {
    navigate(`/${shareKey}`);
  }, [navigate, shareKey]);
  const onItemClick = useCallback(
    (itemId: Key) => {
      logEvent('previewGoTo', 'preview', { shareId: shareKey, focusItem: itemId?.toString() });
      navigate(`/${shareKey}/${itemId.toString()}${action ? `/${action}` : ''}`);
      adjustRibbonVisible(itemId);
    },
    [action, adjustRibbonVisible, navigate, shareKey],
  );
  const { data: assetDetails, isLoading } = useAssetDetail(shareKey || '', focusKey || '', password);
  const canDownload = useCanDownload(focusKey, password);
  const carouselContext = useMemo<ABContext>(() => ({}), []);
  const downloadLink = useDownloadURL();
  const [downloadURL, setDownloadURL] = useState('');
  useOpenGraphProtocol(
    assetDetails
      ? {
          title: assetDetails.name || '',
          images: assetDetails.mimeType?.includes('image')
            ? [
                {
                  url: window.location.origin + thumbnailUrl(shareKey || '', focusKey || '', password),
                },
              ]
            : [],
          description: 'Size: ' + assetDetails.sizeInBytes + ' bytes',
        }
      : undefined,
  );

  useEffect(() => {
    if (!isWidthSmall || !batch || !batch.share || !focusKey || !canDownload) {
      return;
    }

    fetch(
      downloadLink({
        shareKey: batch.share.shareKey,
        assetId: focusKey,
        childAssetId: focusKey,
        password: password,
        skipRedirect: true,
      }),
    ).then(async (response) => {
      if (response.status !== 200) {
        console.log(response.status);
      }

      try {
        const data = await response.json();
        if (data && data.downloadUrl) {
          setDownloadURL(data.downloadUrl);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }, [batch, canDownload, downloadLink, focusKey, isWidthSmall, password, shareKey]);
  const [downloading, setDownloading] = useState(false);

  if (action || !shareKey || !focusKey) {
    return null;
  }

  if (!batch || !batch.share) {
    throw new Error('cannot be rendered without data');
  }

  return (
    <Modal
      disableTransition
      open
      fullScreen
      classes={{
        root: classes.modalRoot,
        contentFullScreen: classes.modalContainer,
      }}
      onClose={() => {
        return gotoShare();
      }}
    >
      <HorizontalNavigation
        transparent={isHeightSmall ? true : undefined}
        classes={{
          tier1: classes.horizontalNavigation,
          logoContainer: classes.logoContainer,
          tier1MainContent: classes.tier1MainContent,
        }}
        style={isHeightSmall ? { position: 'absolute', width: '100%' } : undefined}
        logo={
          <div>
            <AppLogo name='Asset Share' studioLogo classes={{ root: classes.logo }} />
            <Text noWrap classes={{ root: classes.fileName }} component={'span'} loading={isLoading}>
              {assetDetails?.name}
            </Text>
          </div>
        }
        userControls={
          <div className={classes.userControls}>
            <Button
              classes={{ root: classes.downloadButton }}
              loaderProps={{ classes: { root: classes.downloadButtonLoader } }}
              variant={'icon'}
              icon={IconDownload}
              disabled={!canDownload || !focusKey || downloading}
              loading={downloading}
              component={Link}
              to={`/${shareKey}/${focusKey}/download`}
              onClick={async (evt) => {
                // Avoid to download the file if it is already downloading
                if (downloading) {
                  return;
                }
                // Handle the logic for the download button on small screens
                if (
                  isWidthSmall &&
                  'share' in navigator &&
                  'canShare' in navigator &&
                  assetDetails?.mimeType?.startsWith('image')
                ) {
                  evt.preventDefault();
                  setDownloading(true);
                  logEvent('downloadAsset', 'mobile', {
                    assetIds: assetDetails?.assetId || '',
                    mimeType: assetDetails?.mimeType || '',
                    size: assetDetails?.sizeInBytes || 0,
                    experience: 'shareNavigator',
                  });
                  try {
                    let file = files.get(focusKey);
                    if (!file) {
                      const start = performance.now();
                      const request = await fetch(downloadURL);
                      const end = performance.now();
                      logDuration('downloadImageSpeedInMobile', end - start);
                      logEvent('downloadImageSpeedInMobile', 'download', { duration: end - start });
                      const blob = await request.blob();
                      file = new File([blob], assetDetails?.name || 'file', {
                        type: assetDetails?.mimeType || 'application/octet-stream',
                      });
                      files.set(focusKey, file);
                    }
                    const data = {
                      files: [file],
                      title: assetDetails?.name,
                      text: assetDetails?.name,
                    };
                    return await navigator.share(data);
                  } catch (error) {
                    logError(error as Error);
                  } finally {
                    setDownloading(false);
                  }
                  return;
                }
                logEvent('downloadAsset', 'download', {
                  assetIds: assetDetails?.assetId || '',
                  mimeType: assetDetails?.mimeType || '',
                  size: assetDetails?.sizeInBytes || 0,
                  experience: 'redirect',
                });
              }}
            />
            <Button variant='icon' icon={IconX} component={Link} to={`/${shareKey}`} />
          </div>
        }
      />
      <AssetBrowserContext.Provider value={carouselContext}>
        <Carousel
          renderer={DetailPreviewItem}
          loading={false}
          itemIds={assetIds}
          focusKey={focusKey}
          setFocus={onItemClick}
          classes={{
            controls: classes.controls,
          }}
        />
      </AssetBrowserContext.Provider>
      <div className={classes.scrollGallery} ref={ribbonRef}>
        <AssetBrowserContext.Provider value={{ onItemClick, fixedItemHeight: 120, highlight: focusKey }}>
          {batch.assetIds.map((item, index) => (
            <Thumbnail
              key={item.id}
              id={item.id}
              src={thumbnailUrl(shareKey, item.id, password)}
              password={password}
              shareKey={shareKey}
            >
              <CounterChip index={index} total={batch.assetIds.length} />
            </Thumbnail>
          ))}
        </AssetBrowserContext.Provider>
      </div>
    </Modal>
  );
};
